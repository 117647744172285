<script lang="ts" setup>
import GET_BRAND_PAGES from './getBrandPages.graphql'

const route = useRoute()

const variables = {
  limit: 1,
  where: {
    slug: route.params.slug,
  },
}

definePageMeta({
  layout: 'branded',
})

const {
  data,
  error: { value: error },
} = await useAsyncQuery('getBrandpages', GET_BRAND_PAGES, variables)

if (error)
  useHandleError(
    {
      message: error.message,
      name: error.name,
      stack: error.stack,
    },
    route.fullPath,
  )
// Todo: Update selection type and remove any
const brandpage: any = data.value.brandPages?.[0]

if (!brandpage)
  throw createError({
    statusCode: 404,
    statusMessage: 'Merkpagina niet gevonden',
  })
</script>
<template>
  <Metadata :contentItem="brandpage" />
  <BrandedHeader :theme="brandpage.theme" />
  <div>
    <div class="h-44 w-full overflow-hidden md:h-96">
      <EoImg
        v-if="brandpage.hero"
        :src="brandpage.hero[0].cdnFiles[0].url"
        class="w-full"
      />
    </div>
    <div class="mx-auto mb-10 w-full max-w-6xl px-4 py-6 xl:px-0">
      <div v-if="error">{{ error }}</div>
      <div v-else>
        <h1 class="mb-4 pt-5 text-left text-4xl">
          {{ brandpage.title }}
        </h1>
        <div class="mb-4 text-base sm:text-lg/relaxed xl:text-xl/relaxed">
          {{ brandpage.intro }}
        </div>
        <EoPreprContent :items="brandpage.content">
          <template #button="{ data }">
            <EoPreprButton
              :data="data"
              class="no-underline"
              :color="brandpage.theme.secondary"
            />
          </template>
          <template #newsletterSubscribe="{ data }">
            <EoPreprNewsletterSubscribe
              :data="data"
              :color="brandpage.theme.secondary"
            />
          </template>
        </EoPreprContent>
      </div>
    </div>
  </div>
  <BrandedFooter :theme="brandpage.theme" />
</template>
<style>
p {
  @apply sm:text-lg/relaxed xl:text-xl/relaxed !important;
}
</style>
